import api from "../../../lib/api";
import {ENDPOINTS} from "../../../data/endpoints";
import {CashierMovementType} from "../types/CashierMovementType";
import {CashierStatsType} from "../types/CashierStatsType";

const getMovements = async () => {
  return await api.get<CashierMovementType[]>(ENDPOINTS.CASHIER_GET_MOVEMENTS);
}

const getStats = async () => {
  return await api.get<CashierStatsType>(ENDPOINTS.GET_CASHIER_STATS);
}

const createMovement = async (data: CashierMovementType) => {
  return await api.post(ENDPOINTS.CREATE_MOVEMENT, data);
}

const CashierService = {
  createMovement,
  getMovements,
  getStats
}

export default CashierService;
