import {InputText as PRInputText} from "primereact/inputtext";
import React, {FocusEventHandler, useRef} from "react";
import {v4 as uuidv4} from "uuid";

interface InputTextProps {
  name?: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  label?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  invalid?: boolean;
  invalidMessage?: string;
  disabled?: boolean;
}

export const InputText: React.FC<InputTextProps> = (
  {
    name,
    value,
    onChange,
    onBlur,
    label,
    inputClassName,
    wrapperClassName,
    invalid,
    invalidMessage,
    disabled
  }) => {

  const inputId = useRef<string>(uuidv4());

  return (
    <div className={`grid ${wrapperClassName}`}>
      {label && (
        <label className="mb-1 text-sm font-medium text-gray-600"
               htmlFor={inputId.current}>
          {label}
        </label>
      )}
      <PRInputText
        name={name}
        onBlur={onBlur}
        value={value}
        aria-describedby={`${inputId.current}-help`}
        onChange={onChange}
        disabled={disabled}
        id={inputId.current}
        className={`h-8 ${inputClassName} ${invalid ? 'p-invalid' : ''}`}
        pt={{
          root: {
            style: {
              boxShadow: "none"
            }
          }
        }}
      />
      {invalid && invalidMessage &&
        <span className="text-xs text-red-700">
          {invalidMessage}
        </span>
      }
    </div>
  );
};

export default InputText;
