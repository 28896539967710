import React from "react";
import {useNavigate} from "react-router-dom";
import IconButton from "../../../components/IconButton";

interface Props {
  productId: string
}

export const ProductEditButton: React.FC<Props> = ({productId}) => {

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/product/edit/" + productId);
  }

  return (
    <IconButton tooltip="Редакция"
                onClick={onClick}
                icon={"pi pi-pencil"}/>
  )
}

export default ProductEditButton;