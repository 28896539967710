import {useCashierMovements} from "../../features/cashier/hooks/useCashierMovements";
import Spinner from "../../components/Spinner";
import {Message} from "primereact/message";
import DataTable from "../../components/DataTable";
import Column from "../../components/Column";
import {CashierMovementType} from "../../features/cashier/types/CashierMovementType";
import CurrencyService from "../../services/currencyService";
import {format} from "date-fns";
import CashierCreateMovement from "./CashierCreateMovement";
import {ColumnFilterElementTemplateOptions} from "primereact/column";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import InputText from "../../components/InputText";
import {ChangeEvent} from "react";
import {Calendar} from "primereact/calendar";

export const CashierDataView = () => {

  const movementTypes = [
    {key: 'INCOME', label: 'Приход'},
    {key: 'OUTCOME', label: 'Разход'}
  ]

  const deskTypes = [
    {key: "1", label: 'Да'},
    {key: "2", label: 'Не'}
  ]

  const {data, isLoading, isError} = useCashierMovements();

  if (isLoading) return <Spinner/>

  if (isError || !data) {
    return <Message text={"Възникна проблем с изваждането на движения в касиера!"} severity="error" className="w-full"/>
  }

  const rowClass = (data: CashierMovementType) => {
    if (data.type === "OUTCOME") return "bg-red-100"
    if (data.type === "INCOME") return "bg-green-100"
  }

  const amountBody = (data: CashierMovementType) => {
    return CurrencyService.format(data.amount);
  }

  const typeBody = (data: CashierMovementType) => {
    if (data.type === "OUTCOME") return "Разход";
    if (data.type === "INCOME") return "Приход";
  }

  const deskBody = (data: CashierMovementType) => {
    if (data.cashDesk === "1") return "Да";
    if (data.cashDesk === "2") return "Не";
  }

  const dateBody = (data: CashierMovementType) => {
    return format(data.docDate, "dd-MM-yyyy");
  }

  const dateCreatedBody = (data: CashierMovementType) => {
    return format(data.dateCreated!, "dd-MM-yyyy");
  }

  const headerBody = () => {
    return (
      <div>
        <CashierCreateMovement/>
      </div>
    )
  }

  const descElement = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <InputText
        value={options.value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => options.filterApplyCallback(e.target.value)}
      />
    )
  }

  const typeElement = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={movementTypes}
        optionValue="key"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => options.filterApplyCallback(e.value)}
        placeholder="Избери"
        className="p-column-filter h-8"
        pt={{
          input: {
            className: "px-2 py-1"
          }
        }}
        showClear
      />
    );
  };

  const deskElement = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={deskTypes}
        optionValue="key"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => options.filterApplyCallback(e.value)}
        placeholder="Избери"
        className="p-column-filter h-8"
        pt={{
          input: {
            className: "px-2 py-1"
          }
        }}
        showClear
      />
    );
  };
  const docDateElement = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <div>
        <Calendar
          className="h-8"
          showIcon={true}
          value={options.value}
          onChange={(e) => {
            if (e.value !== null && e.value !== undefined) {
              options.filterApplyCallback(e.value);
            }
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <DataTable
        size={"small"}
        paginator={true}
        rows={10}
        value={data}
        rowClassName={rowClass}
        sortMode={"multiple"}
        filterDisplay={"row"}
        header={headerBody}
        showGridlines={true}
      >
        {/*Описание*/}
        <Column sortable
                filter showFilterMenu={false} showClearButton={false} filterElement={descElement} filterMatchMode={"contains"}
                field={"description"} header="Описание"/>

        {/*Сума*/}
        <Column sortable
                showFilterMenu={false}
                field={"amount"} header="Сума" body={amountBody} align={"right"}/>

        {/*Каса*/}
        <Column sortable
                filter showFilterMenu={false} showClearButton={false} filterElement={deskElement}
                field={"cashDesk"} header="Каса" body={deskBody} align={"center"}/>

        {/*Дата на документ*/}
        <Column sortable dataType={"date"}
                showFilterMenu={false} showClearButton={false} filterElement={docDateElement}
                field={"docDate"} header="Дата на документ" body={dateBody} align={"center"}/>

        {/*Дата на създаване*/}
        <Column sortable
                showFilterMenu={false}
                field={"dateCreated"} header="Дата на създаване" body={dateCreatedBody} align={"center"}/>

        {/*Разход*/}
        <Column sortable
                filter showFilterMenu={false} showClearButton={false} filterElement={typeElement}
                field={"type"} header="Разход" body={typeBody} align={"center"}/>
      </DataTable>
    </div>
  )
}

export default CashierDataView;