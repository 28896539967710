import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";
import {toastService} from "../../../services/toastService";
import {useCustomMutation} from "../../../lib/query";
import {CashierMovementType} from "../types/CashierMovementType";
import cashierService from "../services/CashierService";

export const useCashierCreateMovement = (data: CashierMovementType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.refetchQueries(QUERY_KEYS.GET_CASHIER_MOVEMENTS);
    toastService.info('Категорията е създадена!')
  }
  return useCustomMutation(() => cashierService.createMovement(data), onSuccess);
}