import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import orderService from "../services/orderService";

export const useGetOrders = () => {
  const {data, isLoading, isError} = useCustomQuery({
    key: QUERY_KEYS.GET_ORDERS,
    query: () => orderService.getOrders(),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}