import {OrderType} from "../../../features/order/types/OrderType";
import React from "react";
import {useFormik} from "formik";
import InputText from "../../../components/InputText";
import OrderEditDetails from "./OrderEditDetails";
import Button from "../../../components/Button";

interface Props {
  order: OrderType;
}

export const OrderEditForm: React.FC<Props> = ({order}) => {

  const initialValues: OrderType = {
    businessBulstat: order.businessBulstat,
    businessName: order.businessName,
    city: order.city,
    deliveryAddress: order.deliveryAddress,
    email: order.email,
    firstName: order.firstName,
    id: order.id,
    lastName: order.lastName,
    paymentType: order.paymentType,
    phoneNumber: order.phoneNumber,
    postCode: order.postCode,
    region: order.region,
    secondName: order.secondName,
    visitorId: order.visitorId,
  }

  const formik = useFormik<OrderType>({
    initialValues: initialValues,
    onSubmit: () => console.log('da')
  });

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
        <div className="grid grid-cols-1 gap-y-2">
          <InputText
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            label="Име"
          />

          <InputText
            name="secondName"
            value={formik.values.secondName}
            onChange={formik.handleChange}
            label="Презиме"
          />

          <InputText
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            label="Фамилия"
          />
        </div>
        <div className="grid grid-cols-1 gap-y-2">
          <InputText
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            label="Град"
          />

          <InputText
            name="region"
            value={formik.values.region}
            onChange={formik.handleChange}
            label="Област"
          />

          <InputText
            name="postCode"
            value={formik.values.postCode}
            onChange={formik.handleChange}
            label="Пощенски код"
          />
        </div>
        <div className="grid grid-cols-1 gap-y-2">
          <InputText
            name="businessName"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            label="Фирма"
          />


          <InputText
            name="businessBulstat"
            value={formik.values.businessBulstat}
            onChange={formik.handleChange}
            label="Булстат"
          />

          <InputText
            value={""}
            onChange={console.log}
            disabled={true}
            label="Фактура номер"
          />
        </div>
        <div className="grid grid-cols-1 gap-y-2">
          <InputText
            value={formik.values.paymentType === "TBI_PAY" ? "На изплащане (TBI)" : "В брой"}
            label={"Начин на плащане"}
            disabled={true}
          />
        </div>
        <div className="grid grid-cols-1 gap-y-2">
          <InputText
            name="deliveryAddress"
            value={formik.values.deliveryAddress}
            onChange={formik.handleChange}
            label={"Адрес за доставка"}
          />
        </div>
        <div className="grid grid-cols-1 gap-y-2">
          <InputText
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            label={"Телефонен номер"}
          />
        </div>
      </div>

      <OrderEditDetails order={order}/>
      <Button label="Запиши" className="mt-3"/>
    </div>
  )
}

export default OrderEditForm;