import {DataTable as PRDataTable, DataTableProps as Props, DataTableValueArray} from "primereact/datatable";
import React, {ReactNode} from "react";

interface DataTableProps<TValue extends DataTableValueArray> extends Props<TValue> {
  children?: ReactNode;
}

export function DataTable<TValue extends DataTableValueArray>({children, ...rest}: DataTableProps<TValue>): JSX.Element {
  return (
    <PRDataTable<TValue> {...rest}
                         pt={{
                           root: {
                           }
                         }}>
      {children}
    </PRDataTable>
  );
}

export default DataTable;
