import CashierIncome from "./CashierIncome";
import CashierOutcome from "./CashierOutcome";
import CashierProfit from "./CashierProfit";

export const DashboardMain = () => {
  return (
    <div>
       <div className="grid grid-cols-4 gap-5">
         <CashierIncome/>
         <CashierOutcome/>
         <CashierProfit/>
       </div>
    </div>
  )
}

export default DashboardMain;