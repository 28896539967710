import Logo from "../../assets/images/logo.png"
import {useState} from "react";
import {useAuthentication} from "../../features/authentication/hooks/authenticationHook";
import {Message} from "primereact/message";

export const Login = () => {

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [hasError, setHasError] = useState(false);
  const handleError = (value: boolean) => setHasError(value);

  const authenticate = useAuthentication({username: username, password: password}, () => handleError(true));

  const handleLogin = () => {
    handleError(false);
    if (username !== "" && password !== "") {
      authenticate.mutate();
    }
  }

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-sm p-6 bg-white rounded-md">
          <div className="flex justify-center w-full">
            <img src={Logo} width={150} alt="logo"/>
          </div>
          {hasError &&
            <Message severity={"error"} text={"Грешно име или парола"} className="w-full mb-4"/>
          }
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700 text-sm font-semibold mb-2">Потребителско име</label>
            <input type="text"
                   value={username}
                   onChange={(e) => setUsername(e.target.value)}
                   id="username"
                   name="username"
                   className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-[#0077b6]"
                   required/>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 text-sm font-semibold mb-2">Парола</label>
            <input type="password"
                   id="password"
                   name="password"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
                   className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-[#0077b6]"
                   required/>
          </div>
          <button type="button"
                  onClick={handleLogin}
                  className="w-full px-4 py-2 bg-[#0077b6] text-white rounded-md hover:bg-[#2fa7e0] focus:outline-none transition">
            Вход
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login;