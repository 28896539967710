import {CategoryType} from "../types/CategoryType";
import React from "react";
import Button from "../../../components/Button";
import TreeSelect from "../../../components/TreeSelect";
import {useCategoryTreeNode} from "../hooks/useGetCategoryTreeNode";
import {useCategoryUpdate} from "../hooks/useCategoryUpdate";
import {useFormik} from "formik";
import {CategoryUpdateType} from "../types/CategoryUpdateType";
import {TreeSelectChangeEvent} from "primereact/treeselect";
import categoryEditValidationSchema from "../schemes/CategoryEditSchemeType";
import InputText from "../../../components/InputText";

interface Props {
  category: CategoryType
}

export const CategoryEditForm: React.FC<Props> = ({category}) => {

  const {data} = useCategoryTreeNode();

  const options = [
    {key: "0", label: "Без категория", value: null},
    ...(data ?? []),
  ];

  const initialValues: CategoryUpdateType = {
    id: category.id,
    name: category.name,
    slug: category.slug,
    parentCategoryId: category.parentCategoryId ?? "0"
  };

  const onSubmit = () => {
    mutation.mutate();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: categoryEditValidationSchema,
    onSubmit: onSubmit
  });

  const mutation = useCategoryUpdate(formik.values);

  const isFormFieldInvalid = (name: keyof typeof formik.touched) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getInvalidMessage = (name: keyof typeof formik.touched) => {
    return isFormFieldInvalid(name) ? formik.errors[name] : undefined;
  };

  return (
    <form onSubmit={formik.handleSubmit}>

      {/*Име на категорията*/}
      <InputText
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        invalid={isFormFieldInvalid("name")}
        invalidMessage={getInvalidMessage("name")}
        label="Име на категорията"
      />

      {/*Слъг на категорията*/}
      <InputText
        name="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        invalid={isFormFieldInvalid("slug")}
        invalidMessage={getInvalidMessage("slug")}
        label="Слъг на категорията"
        wrapperClassName="mt-2"
      />

      {/*Parent категория*/}
      <TreeSelect
        label="Под категория на"
        options={options}
        wrapperClassName="mt-2"
        value={formik.values.parentCategoryId}
        onChange={(e: TreeSelectChangeEvent) => {
          if (typeof e.value === 'string') {
            formik.setFieldValue("parentCategoryId", e.value);
          }
        }}
      />

      <Button
        loading={mutation.isLoading}
        label="Запази"
        className="mt-2"
      />
    </form>
  )
}

export default CategoryEditForm;