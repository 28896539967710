import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import {TreeNode} from "primereact/treenode";
import categoryService from "../services/categoryService";

export const useCategoryTreeNode = () => {
  const {data, isLoading, isError} = useCustomQuery<TreeNode[]>({
    key: QUERY_KEYS.GET_CATEGORY_TREE_NODE,
    query: () => categoryService.getCategoryTreeNode(),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}