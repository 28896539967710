import api from "../../../lib/api";
import {ENDPOINTS} from "../../../data/endpoints";
import {AuthenticationType} from "../types/AuthenticationType";
import {UserType} from "../types/UserType";

const login = async (authenticationType: AuthenticationType) => {
  return await api.post(ENDPOINTS.AUTHENTICATION, authenticationType);
}

const currentUser = async () => {
  return await api.get<UserType>(ENDPOINTS.CURRENT_USER);
}

const changeCompany = async (companyId: string) => {
  return await api.post(ENDPOINTS.CHANGE_CURRENT_COMPANY + companyId, {})
}

const authenticationService = {
  authenticate: login,
  currentUser,
  changeCompany
}

export default authenticationService;