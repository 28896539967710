import React from "react";

import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { useRoutes } from "react-router-dom";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { routes } from "./routes";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from "react-query/devtools";

function App() {
  const routesContent = useRoutes(routes);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 1,
        staleTime: 5000,
      },
    },
  });

  return (
    <Suspense fallback={"loading"}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <div className="App">{routesContent}</div>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
