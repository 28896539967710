import {TabPanel, TabPanelHeaderTemplateOptions, TabView} from "primereact/tabview";
import {tabPanelPt, tabViewPt} from "../../data/pt";
import ProductMainData from "./product-create/ProductMainData";
import {useFormik} from "formik";
import Button from "../../components/Button";
import ProductDescription from "./product-create/ProductDescription";
import productCreateSchema from "../../features/product/schemes/ProductCreateScheme";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {ProductCreateType} from "../../features/product/types/ProductCreateType";
import {Tooltip} from "primereact/tooltip";
import ProductDetails from "./product-create/ProductDetails";
import {useProductCreate} from "../../features/product/hooks/useProductCreate";
import {toastService} from "../../services/toastService";

export const ProductCreate = () => {

    const initialValues = {
        name: "",
        slug: "",
        description: "",
        categoriesNode: [],
        details: [],
        singlePrice: 0,
        additionalWarrantyPrice: 100,
        hasFreeInstallation: false,
        hasAdditionalWarranty: false,
        hasWifi: false,
        isActive: true,
        isService: false,
        isOnlineStoreProduct: true
    };


    const onSubmit = () => {
        if (!formik.values.description) {
            confirmDialog({
                message: 'Не сте въвели описание на продукта. Сигурни ли сте, че искате да продължите?',
                header: 'Внимание',
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: "Не",
                acceptLabel: "Да",
                accept: () => {
                    createProduct.mutate();
                },
            });
        } else {
            createProduct.mutate();
        }
    }

    const formik = useFormik<ProductCreateType>({
        initialValues: initialValues,
        validationSchema: productCreateSchema,
        onSubmit: onSubmit
    });

    const onSuccess = () => {
        toastService.info('Продукта е създаден')
        formik.resetForm();
    }

    const createProduct = useProductCreate(formik.values, onSuccess);

    const headerMain = (options: TabPanelHeaderTemplateOptions) => {
        return (
            <button className={options.element.props.className}>
        <span onClick={options.onClick} className="p-tabview-title">
          Описание
            {(formik.errors.name || formik.errors.slug) &&
                <i className="custom-target-icon pi pi-exclamation-triangle ml-2 text-red-600 blink"/>
            }
        </span>
            </button>
        );
    }

    const headerDescription = (options: TabPanelHeaderTemplateOptions) => {
        return (
            <button className={options.element.props.className}>
        <span onClick={options.onClick} className="p-tabview-title">
          Описание
            {!formik.values.description &&
                <>
                    <i className="custom-target-icon pi pi-exclamation-triangle ml-2 text-yellow-600"
                       data-pr-tooltip="Няма въведено описание"
                       data-pr-position="right"
                       data-pr-at="right+5 top"
                       data-pr-my="left center-2"/>
                    <Tooltip target=".custom-target-icon"/>
                </>
            }
        </span>
            </button>
        );
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <TabView pt={tabViewPt}>
                <TabPanel headerTemplate={headerMain} pt={tabPanelPt}>
                    <ProductMainData formik={formik}/>
                </TabPanel>
                <TabPanel headerTemplate={headerDescription} pt={tabPanelPt}>
                    <ProductDescription formik={formik}/>
                </TabPanel>
                <TabPanel header="Характеристики" pt={tabPanelPt}>
                    <ProductDetails formik={formik}/>
                </TabPanel>
            </TabView>

            <Button label="Запиши" type={"submit"}/>

            <ConfirmDialog/>
        </form>
    )
}

export default ProductCreate;