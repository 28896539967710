import {TabPanelPassThroughType, TabViewPassThroughType} from "primereact/tabview";
import {ConfirmDialogPassThroughType} from "primereact/confirmdialog";

export const tabPanelPt: TabPanelPassThroughType<any> = {
  headeraction: {
    className: "px-4 py-2 font-semibold tracking-wide",
  },
  content: {
    className: "p-0"
  }
}

export const tabViewPt: TabViewPassThroughType<any> = {
  inkbar: {
    className: "bg-[#0077b6]"
  },
  panelcontainer: {
    className: "px-0 py-3"
  }
}

export const confirmDialogPt: ConfirmDialogPassThroughType<any> = {
  rejectButton: {
    className: "bg-red-100 p-5"
  }
}

export const fileUploadPt: any = {
  choosebutton: {
    className: "bg-red-100 p-5"
  },
  choosebuttonlabel: "Качи"
}
