import {useCategoryTree} from "../../features/category/hooks/useGetCategoryTree";
import TreeTable from "../../components/TreeTable";
import {Column} from "primereact/column";
import CategoryEditButton from "../../features/category/components/CategoryEditButton";
import {CategoryTreeType} from "../../features/category/types/CategoryTreeType";

export const CategorySearch = () => {

  const {data} = useCategoryTree();

  const categoryOption = (category: CategoryTreeType) => {
    return (
      <div>
        <CategoryEditButton categoryId={category.key}/>
      </div>
    )
  }

  return (
    <div>
      <TreeTable value={data}>
        <Column field="name" header="Име" expander/>
        <Column body={categoryOption}
                header="Действия"
                headerStyle={{textAlign: "right"}}
                bodyStyle={{textAlign: "right"}}/>
      </TreeTable>
    </div>
  )
}

export default CategorySearch;