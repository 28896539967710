import {CategoryCreateType} from "../types/CategoryCreateType";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";
import {toastService} from "../../../services/toastService";
import {useCustomMutation} from "../../../lib/query";
import categoryService from "../services/categoryService";

export const useCategoryCreate = (categoryCreateType: CategoryCreateType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.refetchQueries(QUERY_KEYS.GET_CATEGORY_TREE);
    queryClient.invalidateQueries(QUERY_KEYS.GET_CATEGORY_TREE_NODE);
    toastService.info('Категорията е създадена!')
  }
  return useCustomMutation(() => categoryService.createCategory(categoryCreateType), onSuccess);
}