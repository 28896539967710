import {ProductImageType} from "../types/ProductImageType";
import React from "react";
import {useProductImageMainImage} from "../hooks/useProductImageMainImage";
import {useProductDeleteImage} from "../hooks/useProductDeleteImage";

interface Props {
  productId: string;
  image: ProductImageType
}

export const SingleProductImage: React.FC<Props> = ({productId, image}) => {

  const updateMainImage = useProductImageMainImage(productId, image.id);
  const deleteImage = useProductDeleteImage(productId, image.id);

  return (
    <div className={`group relative ${image.isMainImage ? 'border-2 border-[#0b5d87]' : ''}`}>
      <img src={image.url} alt={image.id}/>

      {!image.isMainImage &&
        <div className="hidden group-hover:block absolute bottom-0 text-xs bg-[#2fa7e0] text-white px-2 py-1 rounded cursor-pointer opacity-90"
             onClick={() => updateMainImage.mutate()}>
          Направи главна
        </div>
      }

      {!image.isMainImage &&
        <div className="hidden group-hover:block absolute bottom-0 right-0 text-xs bg-red-400 text-white px-2 py-1 rounded cursor-pointer opacity-90"
             onClick={() => deleteImage.mutate()}>
          Изтрий
        </div>
      }
    </div>
  )
}

export default SingleProductImage;