import {useGetOrders} from "../../features/order/hooks/useGetOrders";
import Spinner from "../../components/Spinner";
import {Message} from "primereact/message";
import {Column} from "primereact/column";
import DataTable from "../../components/DataTable";
import {OrderType} from "../../features/order/types/OrderType";
import {Checkbox} from "primereact/checkbox";
import IconButton from "../../components/IconButton";
import React from "react";
import {useNavigate} from "react-router-dom";

export const OrderSearch = () => {

  const navigate = useNavigate();

  const {data, isLoading, isError} = useGetOrders();

  if (isLoading) {
    return <Spinner/>
  }

  if (isError || !data) {
    return <Message severity={"error"} text={"Възникна проблем с изваждането на поръчките"}/>
  }

  const clientBody = (order: OrderType) => {
    return <div>{order.firstName} {order.secondName} {order.lastName}</div>
  }

  const paymentTypeBody = (order: OrderType) => {
    let paymentType = "";
    let color = "bg-sky-600";

    switch (order.paymentType) {
      case "TBI_PAY":
        paymentType = "На изплащане";
        color = "bg-orange-600"
        break;
      case "CASH_ON_DELIVERY":
        paymentType = "В брой"
        break;
    }

    return (
      <div className={`text-sm ${color} rounded text-white font-semibold w-fit px-2`}>
        {paymentType}
      </div>
    )
  }

  const invoiceBody = (order: OrderType) => {
    return (
      <div>
        <Checkbox checked={!!order.businessBulstat} disabled={true}/>
      </div>
    )
  }

  const optionsBody = (order: OrderType) => {
    return (
      <div>
        <IconButton tooltip="Преглед"
                    onClick={() => navigate("/order/edit/" + order.id)}
                    icon={"pi pi-search"}/>
      </div>
    )
  }

  return (
    <div>
      <DataTable value={data}
                 globalFilterMatchMode={"contains"}
                 size={"small"}>
        <Column
          filter={true}
          showFilterMenu={false}
          body={clientBody}
          header="Клиент"
        />

        <Column
          field="email"
          header="Имейл"
        />

        <Column
          field="phoneNumber"
          header="Тел."
        />

        <Column
          field="city"
          header="Град"
        />

        <Column
          body={paymentTypeBody}
          header="Тип на плащане"
        />

        <Column
          body={invoiceBody}
          header="Фактура на фирма"
        />

        <Column
          field="dateCreated"
          header="Дата на създаване"
        />

        <Column
          body={optionsBody}
        />
      </DataTable>
    </div>
  )
}

export default OrderSearch;