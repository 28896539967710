import React from "react";
import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button";
import Column from "../../../components/Column";
import InputText from "../../../components/InputText"; // Replace with your button library import
import {v4 as uuidv4} from "uuid";
import {useFormik} from "formik";
import {toastService} from "../../../services/toastService";
import {ProductUpdateDetails} from "../../../features/product/types/ProductUpdateDetails";
import {useProductUpdateDetails} from "../../../features/product/hooks/useProductUpdateDetails";
import {ProductDetailType} from "../../../features/product/types/ProductDetailType";

interface Props {
  product: any;
}

export const ProductEditDetails: React.FC<Props> = ({product}) => {

  const formik = useFormik<ProductUpdateDetails>({
    initialValues: {
      productId: product.id!,
      details: product.details,
    },
    onSubmit: () => mutation.mutate()
  });

  console.log(product.details)

  const onSuccess = () => {
    toastService.info("Промените са записани")
  }

  const mutation = useProductUpdateDetails(formik.values, onSuccess);

  const addDetail = () => {
    const newDetail: ProductDetailType = {
      id: uuidv4(),
      name: "",
      value: "",
    };
    formik.setFieldValue("details", [...formik.values.details, newDetail])
  };

  const removeDetail = (index: string) => {
    const updatedDetails = formik.values.details.filter((detail: ProductDetailType) => detail.id !== index);
    formik.setFieldValue("details", updatedDetails)
  };

  const tableHeader = () => {
    return (
      <Button onClick={addDetail} label="Добави" type="button"/>
    )
  }

  const nameBody = (detail: ProductDetailType) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedDetails = formik.values.details.map((d: ProductDetailType) => {
        if (d.id === detail.id) {
          return {...d, name: e.target.value};
        }
        return d;
      });
      formik.setFieldValue("details", updatedDetails);
    };

    return <InputText value={detail.name} onChange={handleChange}/>;
  };

  const valueBody = (detail: ProductDetailType) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedDetails = formik.values.details.map((d: ProductDetailType) => {
        if (d.id === detail.id) {
          return {...d, value: e.target.value};
        }
        return d;
      });
      formik.setFieldValue("details", updatedDetails);
    };

    return <InputText value={detail.value} onChange={handleChange}/>;
  };

  const optionsBody = (detail: ProductDetailType) => {
    return (
      <i className="pi pi-trash text-[#0077b6] hover:text-sky-300 cursor-pointer transition" onClick={() => removeDetail(detail.id!)}/>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <DataTable
        size={"small"}
        header={tableHeader}
        id="index"
        value={formik.values.details}
        emptyMessage="Няма въведени характеристики">
        <Column field="index" header="Номер" body={(data, options) => options.rowIndex + 1}/>
        <Column field="name" header="Име" body={nameBody}/>
        <Column field="value" header="Стойност" body={valueBody}/>
        <Column header="Действия" body={optionsBody}/>
      </DataTable>

      <Button label="Запази" type="submit" className="mt-5"/>
    </form>
  );
};

export default ProductEditDetails;
