import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import productService from "../services/productService";

export const useGetAllProducts = () => {
  const {data, isLoading, isError} = useCustomQuery({
    key: QUERY_KEYS.GET_ALL_PRODUCTS,
    query: () => productService.getAllProducts(),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}