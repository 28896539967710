import {useEffect, useState} from "react";
import {MenuItem} from "primereact/menuitem";
import {useLocation} from "react-router-dom";
import {BreadCrumb as PRBreadCrumb} from "primereact/breadcrumb";

interface LabelMappings {
  [key: string]: string;
}

const labelMappings: LabelMappings = {
  category: "Категории",
  create: "Създай",
  search: "Преглед",
  product: "Продукти",
  order: "Поръчки",
  cart: "Колички",
  edit: "Редакция",
  cashier: "Касиер"
};

export const BreadCrumb = () => {
  const home: MenuItem = { icon: "pi pi-home", url: "/" };
  const { pathname } = useLocation();
  const [items, setItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    const pathSegments = pathname.split("/").filter((segment) => segment !== "");
    const newItems = pathSegments.map((segment, index) => ({
      label: labelMappings[segment] || segment,
      url: pathSegments.slice(0, index + 1).join("/"),
    }));
    setItems(newItems);
  }, [pathname]);

  return (
    <PRBreadCrumb
      model={items}
      home={home}
      pt={{
        root: {
          style: {
            border: "none",
            fontSize: "0.85rem",
            fontWeight: 500,
            letterSpacing: "0.04rem",
            color: "gray"
          },
        },
        separator: {
          style: {
            width: 12,
            color: "gray"
          }
        },
        icon: {
          style: {
            fontSize: 15,
            color: "gray"
          }
        },
        label: {
          className: "text-gray-600",
          style: {
            color: "gray"
          }
        }
      }}
    />
  );
};

export default BreadCrumb;
