import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import cashierService from "../services/CashierService";

export const useCashierStats = () => {
  const {data, isLoading, isError} = useCustomQuery({
    key: QUERY_KEYS.GET_CASHIER_STATS,
    query: () => cashierService.getStats(),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}