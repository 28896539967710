import {TieredMenu as PRTieredMenu} from "primereact/tieredmenu";
import {MenuItem} from "primereact/menuitem";
import React from "react";

interface TieredMenuProps {
  model: MenuItem[] | undefined
}

export const TieredMenu: React.FC<TieredMenuProps> = ({model}) => {
  return (
    <PRTieredMenu model={model}
                  breakpoint="767px"
                  pt={{
                    root: {
                      style: {
                        padding: 0,
                        border: "none",
                        width: '100%',
                        fontSize: '0.80rem',
                        fontWeight: 500,
                        letterSpacing: '0.05rem',
                      }
                    },
                    menu: {
                      style: {
                        padding: 0
                      }
                    },
                    action: {
                      style: {
                        boxShadow: "none",
                      },
                    },
                    label: {
                      className: "text-gray-600"
                    }
                  }}
    />
  )
}

export default TieredMenu;