import {useParams} from "react-router-dom";
import {useGetCategoryById} from "../../features/category/hooks/useGetCategoryById";
import {Message} from "primereact/message";
import Spinner from "../../components/Spinner";
import CategoryEditForm from "../../features/category/components/CategoryEditForm";

export const CategoryEdit = () => {

  const {categoryId} = useParams();
  const {data, isLoading, isError} = useGetCategoryById(categoryId!);

  if (isLoading) {
    return <Spinner/>
  }

  if (isError || !data) {
    return <Message severity="error" text="Не е намерена категория" />
  }

  return (
    <div className="w-full lg:w-1/3">
      <CategoryEditForm category={data}/>
    </div>
  )
}

export default CategoryEdit;