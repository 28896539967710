import {useFormik} from "formik";
import {ProductType} from "../../../features/product/types/ProductType";
import React from "react";
import Button from "../../../components/Button";
import {toastService} from "../../../services/toastService";
import {ProductUpdatePromoType} from "../../../features/product/types/PromoUpdatePromoType";
import {useProductUpdatePromo} from "../../../features/product/hooks/useProductUpdatePromo";
import {Checkbox} from "primereact/checkbox";
import InputCurrency from "../../../components/InputCurrency";

interface Props {
  product: ProductType
}

export const ProductEditPromo: React.FC<Props> = ({product}) => {

  const formik = useFormik<ProductUpdatePromoType>({
    initialValues: {
      productId: product.id!,
      hasPromo: product.hasPromo,
      singlePricePromo: product.singlePricePromo!
    },
    onSubmit: () => mutation.mutate()
  });

  const onSuccess = () => {
    toastService.info("Промените са записани")
  }

  const mutation = useProductUpdatePromo(formik.values, onSuccess);

  return (
    <form className="w-full xl:w-1/3" onSubmit={formik.handleSubmit}>

      <div className="flex items-center mt-2">
        <Checkbox
          id="hasPromo"
          name="hasPromo"
          onChange={formik.handleChange}
          checked={formik.values.hasPromo}
          className="mr-2"
        />
        <label htmlFor="hasPromo">Промоция</label>
      </div>

      <InputCurrency
        label="Основна цена с ДДС"
        name="singlePrice"
        value={product.singlePrice}
        wrapperClassName="mt-2"
        disabled={true}
      />

      <InputCurrency
        label="Промо цена с ДДС"
        name="singlePricePromo"
        value={formik.values.singlePricePromo}
        onChange={e => formik.setFieldValue("singlePricePromo", e.value)}
        wrapperClassName="mt-2"
      />

      <Button label="Запази" className="mt-5" type="submit"/>
    </form>
  )
}

export default ProductEditPromo;