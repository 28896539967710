import React from "react";
import {Button} from "primereact/button";

interface IconButtonProps {
  tooltip: string;
  icon: string;
  onClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = ({tooltip, icon, onClick}) => {

  return (
    <Button icon={icon}
            onClick={onClick}
            rounded
            outlined
            tooltip={tooltip}
            tooltipOptions={{position: "top"}}
            className="mx-0.5 text-[#0077b6]"
            style={{width: 30, height: 30}}/>
  )
}

export default IconButton;