import { MenuItem } from "primereact/menuitem";
import { useNavigate } from "react-router-dom";

const useMenuItems = () => {
  const navigate = useNavigate();

  const menuItems: MenuItem[] = [
    {
      label: 'Основно табло',
      icon: 'pi pi-fw pi-file',
      command: () => navigate("/")
    },
    {
      label: 'Категории',
      icon: 'pi pi-fw pi-pencil',
      items: [
        {
          label: 'Преглед',
          icon: 'pi pi-fw pi-search',
          command: () => navigate("/category/search")
        },
        {
          label: 'Създай категория',
          icon: 'pi pi-fw pi-plus',
          command: () => navigate("/category/create")
        }
      ]
    },
    {
      label: 'Продукти',
      icon: 'pi pi-fw pi-user',
      items: [
        {
          label: 'Преглед',
          icon: 'pi pi-fw pi-search',
          command: () => navigate("/product/search")
        },
        {
          label: 'Създай продукт',
          icon: 'pi pi-fw pi-plus',
          command: () => navigate("/product/create")
        }
      ]
    },
    {
      label: 'Поръчки',
      icon: 'pi pi-fw pi-calendar',
      items: [
        {
          label: 'Преглед',
          icon: 'pi pi-fw pi-search',
          command: () => navigate("/order/search")
        }
      ]
    },
    {
      label: 'Касиер',
      icon: 'pi pi-fw pi-money-bill',
      command: () => navigate("/cashier")
    },
  ];

  return menuItems;
};

export default useMenuItems;
