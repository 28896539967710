import axios, {AxiosInstance, AxiosResponse} from "axios";
import {getWebStorage, removeWebStorage} from "../services/storage";
import {redirect} from "react-router-dom";
import {CONSTANTS} from "../data/constants";

interface IApiService {
  get<T>(url: string): Promise<T>;

  getPdf<T>(url: string): Promise<T>;

  post<T, R>(url: string, data?: R): Promise<T>;

  put<T, R>(url: string, data?: R): Promise<T>;

  patch<T, R>(url: string, data?: R): Promise<T>;

  delete<T>(url: string): Promise<T>;
}

const api: IApiService = (() => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(
    config => {
      const authToken = getWebStorage(CONSTANTS.AUTH_TOKEN);
      if (authToken) {
        const token = getWebStorage(CONSTANTS.AUTH_TOKEN);
        config.headers["Authorization"] = "Bearer " + token;
      }
      // config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error: any) => {
      console.log(error)
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    function (error: { config: any; response: { status: number; }; }) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        // const refreshToken = getWebStorage("REFRESH_TOKEN");
        // const email = getWebStorage("EMAIL");
        removeWebStorage("EMAIL");
        removeWebStorage("AUTH_TOKEN");
        removeWebStorage("REFRESH_TOKEN");
        redirect("/");
      }
      return Promise.reject(error);
    }
  );

  return {
    async getPdf<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.get(url, {
        headers: {"Content-Type": "application/pdf", "Accept": "application/pdf"},
        data: {}
      });
      return response.data;
    },

    async get<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.get(url);
      return response.data;
    },

    async post<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.post(url, data);
      return response.data;
    },

    async put<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.put(url, data);
      return response.data;
    },

    async patch<T, R>(url: string, data: T): Promise<R> {
      const response: AxiosResponse<R> = await axiosInstance.patch(url, data);
      return response.data;
    },

    async delete<T>(url: string): Promise<T> {
      const response: AxiosResponse<T> = await axiosInstance.delete(url);
      return response.data;
    },
  };
})();

export default api;
