import {Column as PRColumn, ColumnProps as PRColumnProps} from "primereact/column";
import React from "react";

interface ColumnProps extends PRColumnProps {
}

export const Column : React.FC<ColumnProps> = (
  {
    ...rest
  }
) => {
  return (
    <PRColumn {...rest}/>
  )
}

export default Column;