import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import categoryService from "../services/categoryService";

export const useGetCategoryById = (categoryId: string) => {
  const {data, isLoading, isError} = useCustomQuery({
    key: [QUERY_KEYS.GET_CATEGORY_BY_ID, categoryId],
    query: () => categoryService.getCategoryById(categoryId),
    keepPreviousData: false,
    refetchOnMount: false
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}