import {useCustomMutation} from "../../../lib/query";
import productService from "../services/productService";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";
import {toastService} from "../../../services/toastService";

export const useProductImageMainImage = (productId: string, imageId: string) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.refetchQueries([QUERY_KEYS.GET_PRODUCT_IMAGES, productId]);
    toastService.info("Промените са запазени!")
  }
  return useCustomMutation(() => productService.updateProductMainImage(productId, imageId), onSuccess);
}