import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import productService from "../services/productService";

export const useGetProductById = (productId: string) => {
  const {data, isLoading, isError} = useCustomQuery({
    key: [QUERY_KEYS.GET_PRODUCT_BY_ID, productId],
    query: () => productService.getProductById(productId),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}