import {useState} from 'react';
import SidebarToggle from "../components/sidebar/SidebarToggle";
import SidebarMenu from "../components/sidebar/SidebarMenu";
import {Navigate, Outlet} from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import {IAuthenticationStore, useAuthenticationStore} from "../features/authentication/store/authenticationStore";

const authSelectors = (state: IAuthenticationStore) => ({
  isLogged: state.isLogged
})

const MainLayout = () => {

  const {isLogged} = useAuthenticationStore(authSelectors);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (!isLogged) {
    return <Navigate to={"/login"}/>
  }

  return (
    <div className="">
      {/*Toggle button*/}
      <SidebarToggle onClick={handleSidebarToggle}/>

      {/*Sidebar*/}
      <div className="relative z-50">
        <aside id="default-sidebar"
               className={`fixed top-0 left-0 w-64 h-screen transition-transform
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'}`}>
          <div className="h-full py-4 bg-white ">
            <SidebarMenu/>
          </div>
        </aside>
      </div>

      {/*Content*/}
      <div className="p-4 sm:ml-64">
        <BreadCrumb/>
        <div className="mt-4 p-4 bg-white rounded text-gray-600">
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
