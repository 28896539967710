import {InputNumber as PRInputNumber, InputNumberChangeEvent} from "primereact/inputnumber";
import React, {FocusEventHandler, useRef} from "react";
import {v4 as uuidv4} from "uuid";

interface InputNumberProps {
  name?: string;
  value: number;
  onChange?: (event: InputNumberChangeEvent) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  label?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  invalid?: boolean;
  invalidMessage?: string;
  disabled?: boolean;
}

export const InputCurrency: React.FC<InputNumberProps> = (
  {
    name,
    value,
    onChange,
    onBlur,
    label,
    inputClassName,
    wrapperClassName,
    invalid,
    invalidMessage,
    disabled
  }) => {

  const inputId = useRef<string>(uuidv4());

  return (
    <div className={`grid ${wrapperClassName}`}>
      {label && (
        <label
          className="mb-1 text-sm font-medium text-gray-600"
          htmlFor={inputId.current}
        >
          {label}
        </label>
      )}
      <PRInputNumber
        name={name}
        onBlur={onBlur}
        value={value}
        aria-describedby={`${inputId.current}-help`}
        onChange={onChange}
        suffix={" лв."}
        minFractionDigits={2}
        maxFractionDigits={2}
        locale="bg-BG"
        inputStyle={{textAlign: "right"}}
        id={inputId.current}
        disabled={disabled}
        className={`h-8 ${inputClassName} ${invalid ? "p-invalid" : ""}`}
        pt={{
          root: {
            style: {
              boxShadow: "none",
            },
          },
        }}
      />
      {invalid && invalidMessage && (
        <span className="text-xs text-red-700">{invalidMessage}</span>
      )}
    </div>
  );
};

export default InputCurrency;
