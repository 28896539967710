export const generateSlug = (name: string): string => {
    return name
        .trim() // Remove whitespace from both ends of the string
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/[^a-zA-Z0-9\u0400-\u04FF-]/g, "") // Remove all non-word characters except hyphens and Cyrillic letters
        .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
        .replace(/^-+/, "") // Trim hyphens from the start of the string
        .replace(/-+$/, ""); // Trim hyphens from the end of the string
};
