import {createStore} from "../../../lib/store";
import {addWebStorage, getWebStorage, removeWebStorage} from "../../../services/storage";
import {CONSTANTS} from "../../../data/constants";

export interface IAuthenticationStore {
  isLogged: boolean,
  setIsLogged: (value: boolean) => void;
  login: (token: string, refreshToken?: string) => void;
  logout: () => void;
}

export const useAuthenticationStore = createStore<IAuthenticationStore>("authentication_store", (set, get) => ({
    isLogged: getWebStorage(CONSTANTS.AUTH_TOKEN) != null,
    setIsLogged: (value: boolean) => set(() => ({isLogged: value})),
    login: (token, refreshToken) => {
      const {isLogged} = get();
      if (isLogged) {
        return;
      }
      addWebStorage(CONSTANTS.AUTH_TOKEN, token);
      if (refreshToken) {
        addWebStorage(CONSTANTS.REFRESH_TOKEN, refreshToken);
      }
      set(state => ({...state, isLogged: true}));
    },
    logout: () => {
      removeWebStorage(CONSTANTS.AUTH_TOKEN);
      removeWebStorage(CONSTANTS.REFRESH_TOKEN);
      set(state => ({...state, isLogged: false}));
    },
  })
)