import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import orderService from "../services/orderService";

export const useGetOrderById = (id: string) => {
  const {data, isLoading, isError} = useCustomQuery({
    key: [QUERY_KEYS.GET_ORDER_BY_ID, id],
    query: () => orderService.getOrderById(id),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}