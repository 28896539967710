import api from "../../../lib/api";
import {ENDPOINTS} from "../../../data/endpoints";
import {OrderType} from "../types/OrderType";

const getOrders = async () => {
  return await api.get<OrderType[]>(ENDPOINTS.GET_ORDERS);
};

const getOrderById = async (id: string) => {
  return await api.get<OrderType>(ENDPOINTS.GET_ORDER_BY_ID + id)
}

const OrderService = {
  getOrders,
  getOrderById,
}

export default OrderService;
