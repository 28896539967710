import {useFormik} from "formik";
import {ProductType} from "../../../features/product/types/ProductType";
import React from "react";
import Button from "../../../components/Button";
import {toastService} from "../../../services/toastService";
import {ProductUpdateDesc} from "../../../features/product/types/ProductUpdateDesc";
import {useProductUpdateDesc} from "../../../features/product/hooks/useProductUpdateDesc";
import {Editor} from "primereact/editor";

interface Props {
  product: ProductType
}

export const ProductEditMainData: React.FC<Props> = ({product}) => {

  const formik = useFormik<ProductUpdateDesc>({
    initialValues: {
      productId: product.id!,
      description: product.description,
    },
    onSubmit: () => mutation.mutate()
  });

  const onSuccess = () => {
    toastService.info("Промените са записани")
  }

  const mutation = useProductUpdateDesc(formik.values, onSuccess);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Editor
        value={formik.values.description}
        onTextChange={(e) => formik.setFieldValue("description", e.htmlValue)}
        style={{height: '320px'}}
      />
      <Button label="Запази" className="mt-5" type="submit"/>
    </form>
  )
}

export default ProductEditMainData;