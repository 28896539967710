import {TreeSelect as PRTreeSelect, TreeSelectProps as PRTreeSelectProps} from "primereact/treeselect";
import React, {useRef} from "react";
import {v4 as uuidv4} from "uuid";

interface TreeSelectProps extends PRTreeSelectProps {
  label?: string;
  wrapperClassName?: string;
}

export const TreeSelect: React.FC<TreeSelectProps> = (
  {
    wrapperClassName,
    label,
    ...rest
  }) => {

  const inputId = useRef<string>(uuidv4());

  return (
    <div className={`grid ${wrapperClassName}`}>
      {label && (
        <label className="mb-1 text-sm font-medium text-gray-600"
               htmlFor={inputId.current}>
          {label}
        </label>
      )}
      <PRTreeSelect {...rest}
                    pt={{
                      root: {
                        className: "h-8"
                      },
                      tree: {
                        root: {
                          className: "p-0"
                        }
                      },
                      label: {
                        className: "p-1 pl-2"
                      }
                    }}/>
    </div>
  )
}

export default TreeSelect;