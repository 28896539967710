import Logo from "../../assets/images/logo.png"
import TieredMenu from "../TieredMenu";
import useMenuItems from "../../data/menu-items";
import {useCurrentUser} from "../../features/authentication/hooks/useCurrentUser";
import Spinner from "../Spinner";
import CurrentCompany from "../../features/company/components/CurrentCompany";

export const SidebarMenu = () => {

  const {data, isLoading, isError} = useCurrentUser();
  const menuItems = useMenuItems();

  if (isLoading || isError || !data) {
    return <Spinner/>
  }

  return (
    <>
      <div className="flex justify-center w-full">
        <img src={Logo} width={100} alt="logo"/>
      </div>
      <div className="my-3 bg-gray-50 p-3 mx-3">
        <div className="text-sm text-gray-500 font-semibold tracking-wider text-center">{data.firstName} {data.lastName}</div>
        <div className="block w-full">
          <CurrentCompany currentCompanyId={data.currentCompany.id}/>
        </div>
      </div>
      <div>
        <TieredMenu model={menuItems}/>
      </div>
    </>
  )
}

export default SidebarMenu;