import {Button as PRButton} from "primereact/button";
import React from "react";

interface Props {
  label: string;
  className?: string;
  loading?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<Props> = (
  {
    label,
    className,
    loading,
    type,
    disabled,
    onClick
  }
) => {

  return (
    <PRButton label={label}
              className={className}
              loading={loading}
              type={type}
              disabled={disabled}
              onClick={onClick}
              pt={{
                root: {
                  className: "px-4 py-1.5 bg-[#0077b6] hover:bg-[#298fc5] border-none"
                },
                label: {
                  className: "font-semibold tracking-wide text-sm"
                }
              }}/>
  )
}

export default Button;