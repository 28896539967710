import DataTable from "../../components/DataTable";
import {useGetAllProducts} from "../../features/product/hooks/useGetAllProducts";
import Spinner from "../../components/Spinner";
import {Message} from "primereact/message";
import {Checkbox} from "primereact/checkbox";
import currencyService from "../../services/currencyService";
import {Column} from "primereact/column";
import ProductEditButton from "./product-create/ProductEditButton";

export const ProductSearch = () => {

  const {data, isLoading, isError} = useGetAllProducts();

  if (isLoading) {
    return <Spinner/>
  }

  if (isError || !data) {
    return <Message severity={"error"}>test</Message>
  }

  const checkboxBody = (value: boolean) => {
    return <Checkbox checked={value} readOnly={true} disabled={true}/>
  }

  const currencyBody = (value: number) => {
    return currencyService.format(value);
  }

  const optionBody = (productId: string) => {
    return (
      <ProductEditButton productId={productId}/>
    )
  }

  return (
    <div>
      <DataTable value={data}
                 filterDisplay="row"
                 paginator={true}
                 rows={10}
                 size={"small"}>

        <Column field="name"
                filter={true}
                filterField="name"
                filterMatchMode={"contains"}
                showFilterMenu={false}
                filterType={"1"}
                style={{width: '50%'}}
                header="Име"/>

        <Column field="singlePrice"
                style={{width: '5%'}}
                alignHeader={"center"}
                align={"right"}
                body={(p) => currencyBody(p.singlePrice)}
                header="Цена"/>

        <Column field="isService"
                header="Услуга"
                alignHeader={"center"}
                style={{width: '5%'}}
                body={(p) => checkboxBody(p.isService)}
                bodyClassName={"text-center"}/>

        <Column field="isActive"
                header="Активен"
                alignHeader={"center"}
                style={{width: '5%'}}
                body={(p) => checkboxBody(p.isActive)}
                headerClassName="justify-center"
                bodyClassName={"text-center"}/>

        <Column field="isOnlineStoreProduct"
                header="Видим в ОМ"
                style={{width: '5%'}}
                alignHeader={"center"}
                body={(p) => checkboxBody(p.isOnlineStoreProduct)}
                headerClassName="justify-center"
                bodyClassName={"text-center"}/>

        <Column field="hasFreeInstallation"
                header="Безпл. монтаж"
                style={{width: '5%'}}
                alignHeader={"center"}
                headerStyle={{textAlign: "center"}}
                body={(p) => checkboxBody(p.hasFreeInstallation)}
                headerClassName="justify-center"
                bodyClassName={"text-center"}/>

        <Column field="hasWifi"
                header="Wi-Fi"
                style={{width: '5%'}}
                alignHeader={"center"}
                body={(p) => checkboxBody(p.hasWifi)}
                headerClassName="justify-center"
                bodyClassName={"text-center"}/>

        <Column field="hasWifi"
                style={{width: '5%'}}
                alignHeader={"center"}
                body={(p) => optionBody(p.id)}
                headerClassName="justify-center"
                bodyClassName={"text-center"}/>
      </DataTable>
    </div>
  )
}

export default ProductSearch;