import {useCategoryTreeNode} from "../../features/category/hooks/useGetCategoryTreeNode";
import TreeSelect from "../../components/TreeSelect";
import {TreeSelectChangeEvent} from "primereact/treeselect";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import {useFormik} from "formik";
import {CategoryCreateType} from "../../features/category/types/CategoryCreateType";
import {useCategoryCreate} from "../../features/category/hooks/useCategoryCreate";
import {useEffect} from "react";
import InputText from "../../components/InputText";

export const CategoryCreate = () => {

  const {data, isLoading, isError} = useCategoryTreeNode();

  const options = [
    {key: "0", label: "Без категория", value: null},
    ...(data ?? []),
  ];

  const initialValues: CategoryCreateType = {
    name: "",
    slug: "",
    parentCategoryId: "0"
  };

  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.name) errors.name = 'Полето е задължително.';
    if (!values.slug) errors.slug = 'Полето е задължително.';
    return errors;
  };

  const onSubmit = () => {
    mutation.mutate();
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit
  });

  const mutation = useCategoryCreate(formik.values);

  useEffect(() => {
    if (mutation.isSuccess) {
      formik.resetForm();
    }
  }, [mutation.isSuccess, formik])

  const isFormFieldInvalid = (name: keyof typeof formik.touched) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getInvalidMessage = (name: keyof typeof formik.touched) => {
    return isFormFieldInvalid(name) ? formik.errors[name] : undefined;
  };

  if (!data || isLoading || isError) {
    return (
      <div className="mx-auto my-auto">
        <Spinner/>
      </div>
    )
  }

  return (
    <div className="w-full md:w-1/3">
      <form onSubmit={formik.handleSubmit}>
        <InputText
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          invalid={isFormFieldInvalid("name")}
          invalidMessage={getInvalidMessage("name")}
          label={"Име на категорията"}/>

        <InputText
          name="slug"
          value={formik.values.slug}
          onChange={formik.handleChange}
          wrapperClassName="mt-2"
          invalid={isFormFieldInvalid("slug")}
          invalidMessage={getInvalidMessage("slug")}
          label={"Слъг на категорията"}/>

        <TreeSelect
          options={options}
          value={formik.values.parentCategoryId}
          onChange={(e: TreeSelectChangeEvent) => {
            if (typeof e.value === 'string') {
              formik.setFieldValue("parentCategoryId", e.value);
            }
          }}
          wrapperClassName="mt-2"
          label="Под категория на"/>

        <Button label="Запази"
                loading={mutation.isLoading}
                className="mt-2"/>
      </form>
    </div>
  )
}

export default CategoryCreate;