import currencyService from "../../services/currencyService";
import {useCashierStats} from "../../features/cashier/hooks/useCashierStats";
import Spinner from "../../components/Spinner";

export const CashierProfit = () => {

  const {data, isLoading, isError} = useCashierStats();

  if (isLoading || isError || !data) return <Spinner/>

  return (
    <div className="bg-gray-50 shadow-md p-2">
      <div className="text-lg font-medium text-sky-500 tracking-wide">
        Печалба
      </div>
      <div className="mt-2 font-medium">
        {currencyService.format(data.profit)}
      </div>
    </div>
  )
}

export default CashierProfit;