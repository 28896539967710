import React, {useState} from "react";
import InputText from "../../components/InputText";
import {Dropdown} from "primereact/dropdown";
import Button from "../../components/Button";
import {CashierMovementType} from "../../features/cashier/types/CashierMovementType";
import InputCurrency from "../../components/InputCurrency";
import {useCashierCreateMovement} from "../../features/cashier/hooks/useCashierCreateMovement";
import {toastService} from "../../services/toastService";
import {Calendar} from "primereact/calendar";

export const CashierCreateMovement = () => {

  const [show, setShow] = useState<boolean>(false);

  const movementTypes = [
    {key: "INCOME", label: "Приход"},
    {key: "OUTCOME", label: "Разход"}
  ]

  const deskTypes = [
    {key: "1", label: "Да"},
    {key: "2", label: "Не"}
  ]

  const [desc, setDesc] = useState<string>("");
  const [type, setType] = useState<"INCOME" | "OUTCOME">("INCOME");
  const [invoice, setInvoice] = useState<string>("");
  const [desk, setDesk] = useState<string>("1");
  const [amount, setAmount] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());

  const movement: CashierMovementType = {
    amount: amount,
    cashDesk: desk,
    description: desc,
    invoiceNo: invoice,
    type: type,
    docDate: date
  }

  const createMovement = useCashierCreateMovement(movement);

  const handleButton = () => {
    if (desk !== "" && amount !== 0) {
      createMovement.mutate();
    } else {
      toastService.warning("Няма попълнено описание или сума!")
    }
  }

  if (!show) {
    return (
      <div onClick={() => setShow(true)}
           className="text-gray-600 font-semibold text-sm bg-sky-600 hover:bg-[#69c7f1] transition cursor-pointer text-white w-fit py-1 px-3 rounded">
        Създай движение
      </div>
    );
  }

  return (
    <div>
      <div className="text-gray-600 mb-3 font-semibold">Създай движение</div>
      <div>
        <InputText
          label="Описание"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />

        <div className="grid grid-cols-2 gap-2 mt-2">
          <div>
            <div className="text-sm font-medium">Тип</div>
            <Dropdown
              value={type}
              onChange={(e) => setType(e.target.value)}
              options={movementTypes}
              optionLabel="label"
              optionValue="key"
              className="w-full mt-0.5"
              pt={{
                input: {
                  className: "h-8 py-1 w-full"
                }
              }}
            />
          </div>

          <div>
            <InputText
              label="Фактура №"
              value={invoice}
              onChange={(e) => setInvoice(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 mt-2 gap-2">
          <div>
            <div className="text-sm font-medium">Каса</div>
            <Dropdown
              value={desk}
              onChange={(e) => setDesk(e.target.value)}
              optionValue="key"
              optionLabel="label"
              options={deskTypes}
              className="w-full m-0.5"
              pt={{
                input: {
                  className: "h-8 py-1 w-full"
                }
              }}
            />
          </div>
          <div>
            <InputCurrency
              label="Сума"
              value={amount}
              onChange={(e) => setAmount(e.value!)}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 mt-2 gap-2">
          <div>
            <div className="text-sm font-medium">Дата на документа</div>
            <Calendar
              value={date}
              dateFormat="dd-mm-yy"
              onChange={(e) => setDate(e.value as Date)}
              className="w-full"
              pt={{
                input: {
                  root: {
                    className: "h-8"
                  }
                }
              }}
            />
          </div>
        </div>

        <div className="mt-3">
          <Button label="Създай" onClick={handleButton}/>
          <Button label="Затвори" className="ml-3" onClick={() => setShow(false)}/>
        </div>
      </div>
    </div>
  )
}

export default CashierCreateMovement;