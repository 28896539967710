export const ENDPOINTS = {

  // Authentication
  AUTHENTICATION: "/auth/login",
  CURRENT_USER: "/user",
  CHANGE_CURRENT_COMPANY: "/user/company/",

  // Categories
  GET_CATEGORY_TREE: "/category/tree",
  GET_CATEGORY_BY_ID: "/category/",
  CATEGORY_TREE_NODE: "/category/tree-node",
  CATEGORY_CREATE: "/category",
  CATEGORY_UPDATE: "/category/update",

  // Product
  CREATE_PRODUCT: "/product",
  UPDATE_PRODUCT: "/product",
  GET_ALL_PRODUCTS: "/product",
  GET_PRODUCT_BY_ID: "/product/",
  GET_PRODUCT_IMAGES: "/product/images/",
  PRODUCT_UPDATE_MAIN_DATA: "/product/main",
  PRODUCT_UPDATE_DESC: "/product/desc",
  PRODUCT_UPDATE_DETAILS: "/product/details",
  PRODUCT_UPDATE_PROMO: "/product/promo",
  PRODUCT_UPLOAD_IMAGE: "/product/images",
  PRODUCT_UPDATE_MAIN_IMAGE: "/product/images/main/",
  PRODUCT_DELETE_IMAGE: "/product/images/",

  // Orders
  GET_ORDERS: "/order",
  GET_ORDER_BY_ID: "/order/",

  // Cashier
  CASHIER_GET_MOVEMENTS: "/cashier",
  CREATE_MOVEMENT: "/cashier",
  GET_CASHIER_STATS: "/cashier/stats"
};
