import React, {ReactNode} from "react";
import {TreeTable as PRTreeTable, TreeTableProps as PRTreeTableProps} from "primereact/treetable";

interface TreeTableProps extends PRTreeTableProps {
  children?: ReactNode;
}

export const TreeTable: React.FC<TreeTableProps> = ({children, ...rest}) => {
  return (
    <PRTreeTable {...rest}>
      {children}
    </PRTreeTable>
  );
}

export default TreeTable;
