import {AuthenticationType} from "../types/AuthenticationType";
import authenticationService from "../services/authenticationService";
import {useMutation} from "../../../hooks/queryHooks";
import {useAuthenticationStore} from "../store/authenticationStore";
import {useNavigate} from "react-router-dom";

export const useAuthentication = (authenticationType: AuthenticationType, onError?: () => void) => {
  const authenticate = useAuthenticationStore(state => state.login);
  const navigate = useNavigate();

  const onSuccess = (response: any) => {
    authenticate(response.token);
    navigate("/")
  };
  return useMutation(() => authenticationService.authenticate(authenticationType), onSuccess, onError)
}