import React, {useRef, useState} from "react";
import {FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions} from "primereact/fileupload";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import Spinner from "../../../components/Spinner";
import {storage} from "../../../lib/firebase";
import {toastService} from "../../../services/toastService";
import {useQueryClient} from "react-query";
import axios from "axios";
import {ENDPOINTS} from "../../../data/endpoints";
import {QUERY_KEYS} from "../../../data/query-keys";
import ProductImagesComponent from "../../../features/product/components/ProductImagesComponent";

interface Props {
  product: any;
}

export const ProductEditImages: React.FC<Props> = ({product}) => {

  const queryClient = useQueryClient();
  const fileUploader = useRef<FileUpload>(null);
  const [uploadProgress, setUploadProgress] = useState<boolean>(false);

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: false,
    label: 'Качи снимки',
    className: 'custom-choose-btn p-button-rounded p-button-outlined'
  };

  const saveImages = async (imageUrls: string[]) => {

    const requestPayload = {
      productId: product.id,
      imageUrls: imageUrls,
    };

    await axios.patch(process.env.REACT_APP_API_URL + ENDPOINTS.PRODUCT_UPLOAD_IMAGE, requestPayload)
  };

  const uploadHandler = async (event: FileUploadHandlerEvent) => {
    setUploadProgress(true);
    const uploadTasks = event.files.map((file) => {
      const {v4: uuidv4} = require('uuid');
      const uuid = uuidv4();
      const fileExtension = file.name.split('.').pop();

      const storageRef = ref(storage, `/images/${uuid}.${fileExtension}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise<string>((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          },
          (error) => reject(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => resolve(url))
              .catch((error) => reject(error));
          }
        );
      });
    });

    await Promise.all(uploadTasks)
                 .then((e) => saveImages(e))
                 .then(() => fileUploader.current!.clear())
                 .then(() => toastService.info("Файловете са качени."))
                 .then(() => setUploadProgress(false))
                 .then(() => queryClient.refetchQueries([QUERY_KEYS.GET_PRODUCT_IMAGES, product.id]))
  }

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const {className, chooseButton, uploadButton, cancelButton} = options;

    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          {uploadProgress &&
            <Spinner/>
          }
        </div>
      </div>
    );
  };

  return (
    <div>
      <FileUpload ref={fileUploader}
                  mode={"advanced"}
                  accept="image/*"
                  auto={true}
                  multiple={true}
                  customUpload={true}
                  maxFileSize={1000000}
                  chooseOptions={chooseOptions}
                  uploadHandler={uploadHandler}
                  headerTemplate={headerTemplate}/>

      <ProductImagesComponent productId={product.id}/>
    </div>
  )
}

export default ProductEditImages;