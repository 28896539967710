import React, {useState} from "react";
import {OrderDetailType} from "../../../features/order/types/OrderDetailType";
import {DataView} from "primereact/dataview";
import currencyService from "../../../services/currencyService";
import {OrderType} from "../../../features/order/types/OrderType";
import {Dialog} from "primereact/dialog";

interface Props {
  order: OrderType
}

export const OrderEditDetails: React.FC<Props> = ({order}) => {

  const [tbiInfoShow, setTbiInfoShow] = useState<boolean>(false);

  const headerTemplate = () => {
    return (
      <div className="flex justify-between w-100">
        <div className="text-sm text-gray-600 tracking-wide">
          Детайли на поръчка
        </div>
        <div>
          {order.tbiOrderId &&
            <div onClick={() => setTbiInfoShow(true)}
                 className="text-sm bg-orange-500 hover:bg-orange-400 rounded px-2 py-0.5 transition cursor-pointer">
              tbi <span className="text-white">инфо</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const footerTemplate = () => {
    return (
      <div className="text-sm text-gray-600 tracking-wide text-right">
        Общо: {currencyService.format(order.totalPrice!)}
      </div>
    )
  }

  const itemTemplate = (detail: OrderDetailType) => {
    return (
      <div className="flex items-center space-x-4 p-1">
        <div>
          <img src={detail.product.mainImageUrl} alt="Product 1" className="w-20 h-20"/>
        </div>
        <div className="flex-grow">
          <h3 className="text-lg font-medium">{detail.product.name}</h3>
          <p className="text-sm text-gray-500 mt-2">
            <span className="mr-2">
              Цена:
              <span className="font-semibold ml-1">
                {currencyService.format(detail.price)}
              </span>
            </span>
            <span className="mx-2">
              Количество: <span className="font-semibold">{detail.quantity}</span>
            </span>
            {detail.product.brand &&
              <span className="mx-2">
                Марка: <span className="font-semibold">{detail.product.brand}</span>
              </span>
            }
          </p>
        </div>

        <Dialog header="TBI информация"
                draggable={false}
                visible={tbiInfoShow} onHide={() => setTbiInfoShow(false)}
                style={{width: '50vw'}}>
          <p className="m-0">
            <div className="mt-2">
              Поръчка №: <span className="font-semibold"> {order.tbiOrderId}</span>
            </div>
            <div className="mt-2">
              Token: <span className="font-semibold"> {order.tbiToken}</span>
            </div>
            <div className="mt-2">
              URL: <span className="font-semibold"> {order.tbiUrl}</span>
            </div>
          </p>
        </Dialog>
      </div>
    )
  }

  return (
    <div className="mt-5">
      <DataView
        value={order.details}
        header={headerTemplate()}
        footer={footerTemplate()}
        itemTemplate={itemTemplate}
      />
    </div>
  )
}

export default OrderEditDetails;