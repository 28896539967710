import {useFormik} from "formik";
import productCreateSchema from "../../../features/product/schemes/ProductCreateScheme";
import {ProductType} from "../../../features/product/types/ProductType";
import React, {useEffect} from "react";
import InputText from "../../../components/InputText";
import TreeSelect from "../../../components/TreeSelect";
import InputCurrency from "../../../components/InputCurrency";
import {Checkbox} from "primereact/checkbox";
import {ProductUpdateMainData} from "../../../features/product/types/ProductUpdateMainData";
import {useCategoryTreeNode} from "../../../features/category/hooks/useGetCategoryTreeNode";
import Button from "../../../components/Button";
import {useProductUpdateMainData} from "../../../features/product/hooks/useProductUpdateMainData";
import {toastService} from "../../../services/toastService";

interface Props {
  product: ProductType
}

export const ProductEditMainData: React.FC<Props> = ({product}) => {

  const {data} = useCategoryTreeNode();

  const formik = useFormik<ProductUpdateMainData>({
    initialValues: {
      id: product.id!,
      name: product.name,
      slug: product.slug,
      categories: [],
      singlePrice: product.singlePrice,
      additionalWarrantyPrice: product.additionalWarrantyPrice,
      hasFreeInstallation: product.hasFreeInstallation,
      hasAdditionalWarranty: product.hasAdditionalWarranty,
      hasWifi: product.hasWifi,
      isFeatured: product.isFeatured,
      isActive: product.isActive,
      isService: product.isService,
      isOnlineStoreProduct: product.isOnlineStoreProduct,
    },
    validationSchema: productCreateSchema,
    onSubmit: () => productUpdateMainData.mutate()
  });

  const onSuccess = () => {
    toastService.info("Промените са записани")
  }

  const productUpdateMainData = useProductUpdateMainData(formik.values, onSuccess);

  const isFormFieldInvalid = (name: keyof typeof formik.touched): boolean =>
    !!(formik.touched[name] && formik.errors[name]);

  const getInvalidMessage = (name: keyof typeof formik.touched): string | undefined =>
    isFormFieldInvalid(name) ? String(formik.errors[name]) : undefined;

  useEffect(() => {
    if (product) {
      const cats = product.categories?.reduce((acc, e) => ({...acc, [e.id]: true}), {});
      formik.setFieldValue("categories", cats);
    }
  }, [formik, product]);

  useEffect(() => {
    // console.log(formik.values.categories)
  }, [formik.values.categories])


  return (
    <form className="w-full xl:w-1/3" onSubmit={formik.handleSubmit}>
      <InputText
        label="Име на продукта"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        invalid={isFormFieldInvalid("name")}
        invalidMessage={getInvalidMessage("name")}
      />

      <InputText
        label="Слъг"
        name="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        wrapperClassName="mt-2"
        invalid={isFormFieldInvalid("slug")}
        invalidMessage={getInvalidMessage("slug")}
      />

      <div className="mt-2">
        <label>Категории</label>
        <TreeSelect
          filter
          onChange={(e: any) => formik.setFieldValue("categories", e.value)}
          options={data}
          value={formik.values.categories}
          metaKeySelection={false}
          className="md:w-20rem w-full"
          selectionMode="multiple"
          placeholder="Избери категории"
        />
      </div>

      <InputCurrency
        label="Цена с ДДС"
        name="singlePrice"
        value={formik.values.singlePrice}
        onChange={e => formik.setFieldValue("singlePrice", e.value)}
        wrapperClassName="mt-2"
        invalid={isFormFieldInvalid("singlePrice")}
        invalidMessage={getInvalidMessage("singlePrice")}
      />

      <div className="flex items-center mt-2">
        <Checkbox
          id="hasFreeInstallation"
          name="hasFreeInstallation"
          onChange={formik.handleChange}
          checked={formik.values.hasFreeInstallation}
          className="mr-2"
        />
        <label htmlFor="hasFreeInstallation">Безплатен монтаж</label>
      </div>


      <div className="flex items-center mt-2">
        <Checkbox
          id="hasWifi"
          name="hasWifi"
          onChange={formik.handleChange}
          checked={formik.values.hasWifi}
          className="mr-2"
        />
        <label htmlFor="hasWifi">Wifi</label>
      </div>

      <div className="flex items-center mt-2">
        <Checkbox
          id="isService"
          name="isService"
          onChange={formik.handleChange}
          checked={formik.values.isService}
          className="mr-2"
        />
        <label htmlFor="isService">Услуга</label>
      </div>

      <div className="flex items-center mt-2">
        <Checkbox
          id="isActive"
          name="isActive"
          onChange={formik.handleChange}
          checked={formik.values.isActive}
          className="mr-2"
        />
        <label htmlFor="isActive">Активен</label>
      </div>

      <div className="flex items-center mt-2">
        <Checkbox
          id="isOnlineStoreProduct"
          name="isOnlineStoreProduct"
          onChange={formik.handleChange}
          checked={formik.values.isOnlineStoreProduct}
          className="mr-2"
        />
        <label htmlFor="isOnlineStoreProduct">Да се показва в онлайн магазина</label>
      </div>

      <div className="flex items-center mt-2">
        <Checkbox
          id="isFeatured"
          name="isFeatured"
          onChange={formik.handleChange}
          checked={formik.values.isFeatured}
          className="mr-2"
        />
        <label htmlFor="hasFreeInstallation">Специален продукт</label>
      </div>

      <div className="flex items-center mt-2">
        <Checkbox
          id="hasAdditionalWarranty"
          name="hasAdditionalWarranty"
          onChange={formik.handleChange}
          checked={formik.values.hasAdditionalWarranty}
          className="mr-2"
        />
        <label htmlFor="hasAdditionalWarranty">Опция за доп. гаранция</label>
      </div>

      {formik.values.hasAdditionalWarranty &&
        <InputCurrency
          label="Цена на доп. гаранция с ДДС"
          name="additionalWarrantyPrice"
          value={formik.values.additionalWarrantyPrice}
          onChange={e => formik.setFieldValue("additionalWarrantyPrice", e.value)}
          wrapperClassName="mt-2"
          invalid={isFormFieldInvalid("additionalWarrantyPrice")}
          invalidMessage={getInvalidMessage("additionalWarrantyPrice")}
        />
      }

      <Button label="Запази" className="mt-5" type="submit"/>
    </form>
  )
}

export default ProductEditMainData;
