import {Dropdown} from "primereact/dropdown";
import React, {useState} from "react";
import {useMutation} from "react-query";
import authenticationService from "../../authentication/services/authenticationService";
import {toastService} from "../../../services/toastService";

interface Props {
  currentCompanyId: string;
}

export const CurrentCompany: React.FC<Props> = ({currentCompanyId}) => {

  const companies = [
    {key: '685faf76-fdea-44a2-baba-ea3d37fb9cd4', label: 'Коднетикс ЕООД',},
    {key: '2ada9456-6bf1-432a-8df3-1cdeca1438c3', label: 'НСВ ЕООД',}
  ]

  const [currentCompany] = useState<string>(currentCompanyId);

  const {mutate} = useMutation((e: string) => authenticationService.changeCompany(e), {
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {
      toastService.error("Възникна грешка при опит за смяна на компанията.")
    }
  });

  const handleCompanyChange = (id: string) => {
    mutate(id);
  }

  return (
    <div>
      <Dropdown
        options={companies}
        value={currentCompany}
        optionValue="key"
        optionLabel="label"
        className="h-8 mt-3 w-full"
        onChange={(e) => handleCompanyChange(e.target.value)}
        pt={{
          input: {
            className: "px-3 p-1 w-full"
          }
        }}
      />
    </div>
  )
}

export default CurrentCompany;