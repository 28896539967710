import {useCustomQuery} from "../../../lib/query";
import {QUERY_KEYS} from "../../../data/query-keys";
import authenticationService from "../services/authenticationService";
import {UserType} from "../types/UserType";

export const useCurrentUser = () => {
  const {data, isLoading, isError} = useCustomQuery<UserType>({
    key: QUERY_KEYS.GET_CURRENT_USER,
    query: () => authenticationService.currentUser(),
  });

  if (isLoading || isError || !data) {
    return {isLoading, isError};
  }

  return {data, isLoading, isError};
}