import {FormikProps} from "formik";
import React from "react";
import {Editor} from "primereact/editor";

interface Props {
  formik: FormikProps<any>;
}

export const ProductDescription: React.FC<Props> = ({formik}) => {
  return (
    <Editor
      value={formik.values.description}
      onTextChange={(e) => formik.setFieldValue("description", e.htmlValue)}
      style={{height: '320px'}}
    />
  )
}

export default ProductDescription;