import React from "react";
import {useGetProductImages} from "../hooks/useGetProductImages";
import Spinner from "../../../components/Spinner";
import {Message} from "primereact/message";
import SingleProductImage from "./SingleProductImage";

interface Props {
  productId: string;
}

export const ProductImagesComponent: React.FC<Props> = ({productId}) => {

  const {data, isLoading, isError} = useGetProductImages(productId);

  if (isLoading) return <Spinner/>
  if (isError || !data) return <Message severity="error" text="Възникна проблем с изваждането на снимки! Моля, опитайте по-късно."/>

  return (
    <div className="mt-5 grid grid-cols-6 gap-2">
      {data.map(e => <SingleProductImage image={e} productId={productId}/>)}
    </div>
  )
}

export default ProductImagesComponent;