import React from "react";
import DataTable from "../../../components/DataTable";
import {ProductDetailType} from "../../../features/product/types/ProductDetailType";
import Button from "../../../components/Button";
import Column from "../../../components/Column";
import InputText from "../../../components/InputText"; // Replace with your button library import
import {v4 as uuidv4} from "uuid";

interface Props {
  formik: any;
}

export const ProductDetails: React.FC<Props> = ({formik}) => {

  const addDetail = () => {
    const newDetail: ProductDetailType = {
      key: uuidv4(),
      name: "",
      value: "",
    };
    formik.setFieldValue("details", [...formik.values.details, newDetail])
  };

  const removeDetail = (index: string) => {
    const updatedDetails = formik.values.details.filter((detail: ProductDetailType) => detail.key !== index);
    formik.setFieldValue("details", updatedDetails)
  };

  const tableHeader = () => {
    return (
      <Button onClick={addDetail} label="Добави"/>
    )
  }

  const nameBody = (detail: ProductDetailType) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedDetails = formik.values.details.map((d: ProductDetailType) => {
        if (d.key === detail.key) {
          return {...d, name: e.target.value};
        }
        return d;
      });
      formik.setFieldValue("details", updatedDetails);
    };

    return <InputText value={detail.name} onChange={handleChange}/>;
  };

  const valueBody = (detail: ProductDetailType) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedDetails = formik.values.details.map((d: ProductDetailType) => {
        if (d.key === detail.key) {
          return {...d, value: e.target.value};
        }
        return d;
      });
      formik.setFieldValue("details", updatedDetails);
    };

    return <InputText value={detail.value} onChange={handleChange}/>;
  };

  const optionsBody = (detail: ProductDetailType) => {
    return (
      <i className="pi pi-trash text-[#0077b6] hover:text-sky-300 cursor-pointer transition" onClick={() => removeDetail(detail.key!)}/>
    )
  }

  return (
    <div>
      <DataTable
        size={"small"}
        header={tableHeader}
        key="index"
        value={formik.values.details}
        emptyMessage="Няма въведени характеристики">
        <Column field="index" header="Номер" body={(data, options) => options.rowIndex + 1}/>
        <Column field="name" header="Име" body={nameBody}/>
        <Column field="value" header="Стойност" body={valueBody}/>
        <Column header="Действия" body={optionsBody}/>
      </DataTable>
    </div>
  );
};

export default ProductDetails;
