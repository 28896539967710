import {useParams} from "react-router-dom";
import Spinner from "../../components/Spinner";
import {Message} from "primereact/message";
import {useGetProductById} from "../../features/product/hooks/useGetProductById";
import {TabPanel, TabView} from "primereact/tabview";
import {tabPanelPt, tabViewPt} from "../../data/pt";
import ProductEditMainData from "./product-edit/ProductEditMainData";
import React from "react";
import ProductEditDesc from "./product-edit/ProductEditDesc";
import ProductEditDetails from "./product-edit/ProductEditDetails";
import {useLocalStorage} from "primereact/hooks";
import ProductEditPromo from "./product-edit/ProductEditPromo";
import ProductEditImages from "./product-edit/ProductEditImages";

export const ProductEdit = () => {

  const [tab, setTab] = useLocalStorage(0, "product-edit-tab")

  const {productId} = useParams();
  const {data, isLoading, isError} = useGetProductById(productId!);

  if (isLoading) {
    return <Spinner/>
  }

  if (isError || !data) {
    return <Message severity="error" text="Не е намерена категория"/>
  }

  const onTabChange = (tabId: number) => {
    setTab(tabId);
  }

  return (
    <TabView pt={tabViewPt}
             activeIndex={tab}
             onTabChange={(e) => onTabChange(e.index)}>
      <TabPanel header="Основни данни" pt={tabPanelPt}>
        <ProductEditMainData product={data}/>
      </TabPanel>
      <TabPanel header="Описание" pt={tabPanelPt}>
        <ProductEditDesc product={data}/>
      </TabPanel>
      <TabPanel header="Характеристики" pt={tabPanelPt}>
        <ProductEditDetails product={data}/>
      </TabPanel>
      <TabPanel header="Снимки" pt={tabPanelPt}>
        <ProductEditImages product={data}/>
      </TabPanel>
      <TabPanel header="Промоция" pt={tabPanelPt}>
        <ProductEditPromo product={data}/>
      </TabPanel>
    </TabView>
  )
}

export default ProductEdit;