import {useGetOrderById} from "../../features/order/hooks/useGetOrderById";
import {useParams} from "react-router-dom";
import Spinner from "../../components/Spinner";
import {Message} from "primereact/message";
import OrderEditForm from "./order-edit/OrderEditForm";

export const OrderEdit = () => {

  const {orderId} = useParams();
  const {data, isLoading, isError} = useGetOrderById(orderId!)

  if (isLoading) {
    return <Spinner/>
  }

  if (isError || !data) {
    return <Message severity={"error"} text={"Възникна проблем"}/>
  }

  return (
    <OrderEditForm order={data}/>
  )
}

export default OrderEdit;