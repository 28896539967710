import {useMutation as useReactMutation, useQuery as useReactQuery} from "react-query";

export type QueryPros<T> = {
  key: string | string[];
  query: () => Promise<T>;
  keepPreviousData?: boolean;
  refetchOnMount?: boolean;
};

export const useQuery = <T>({key, query, keepPreviousData}: QueryPros<T>) => {
  return useReactQuery<T>({queryKey: key, queryFn: query, keepPreviousData: keepPreviousData});
};

export const useMutation = <T>(request: () => Promise<T>, onSuccess?: (data: T) => void, onError?: () => void) => {
  const fetchData = async (): Promise<T> => await request();
  return useReactMutation({
    mutationFn: fetchData,
    onSuccess: (data: T) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data: T) => {
      if (onError) {
        onError()
      }
    }
  });
};