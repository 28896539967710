import * as Yup from 'yup';

const productCreateSchema = Yup.object().shape({
  name: Yup.string().required('Полето е задължително'),
  slug: Yup.string().required('Полето е задължително'),
  singlePrice: Yup.number()
                  .typeError("Полето трябва да бъде число")
                  .min(1, "Полето е задължително"),
  hasAdditionalWarranty: Yup.boolean(),
  additionalWarrantyPrice: Yup.number()
                              .when("hasAdditionalWarranty", {
                                is: true,
                                then: Yup.number()
                                         .min(1, "Полето е задължително")
                                         .typeError("Полето трябва да бъде число"),
                                otherwise: Yup.number().optional()
                              }),
});

export default productCreateSchema;
