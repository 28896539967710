import {toastService} from "../../../services/toastService";
import {useCustomMutation} from "../../../lib/query";
import categoryService from "../services/categoryService";
import {CategoryUpdateType} from "../types/CategoryUpdateType";
import {useQueryClient} from "react-query";
import {QUERY_KEYS} from "../../../data/query-keys";

export const useCategoryUpdate = (categoryUpdateType: CategoryUpdateType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.refetchQueries(QUERY_KEYS.GET_CATEGORY_TREE)
    toastService.info('Промените са отразени!')
  }
  return useCustomMutation(() => categoryService.updateCategory(categoryUpdateType), onSuccess);
}