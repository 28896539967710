import {useCashierStats} from "../../features/cashier/hooks/useCashierStats";
import Spinner from "../../components/Spinner";
import currencyService from "../../services/currencyService";

export const CashierIncome = () => {

  const {data, isLoading, isError} = useCashierStats();

  if (isLoading || isError || !data) return <Spinner/>

  return (
    <div className="bg-gray-50 shadow-md p-2">
      <div className="text-lg font-medium text-green-500 tracking-wide">
        Приходи
      </div>
      <div className="mt-2 font-medium">
        {currencyService.format(data.income)}
      </div>
    </div>
  )
}

export default CashierIncome;