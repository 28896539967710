import MainLayout from "./layouts/MainLayout";
import DashboardMain from "./pages/dashboard/DashboardMain";
import CategoryCreate from "./pages/category/CategoryCreate";
import CategorySearch from "./pages/category/CategorySearch";
import ProductSearch from "./pages/product/ProductSearch";
import ProductCreate from "./pages/product/ProductCreate";
import OrderSearch from "./pages/order/OrderSearch";
import CartSearch from "./pages/cart/CartSearch";
import CategoryEdit from "./pages/category/CategoryEdit";
import ProductEdit from "./pages/product/ProductEdit";
import OrderEdit from "./pages/order/OrderEdit";
import Cashier from "./pages/cashier/Cashier";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./pages/auth/Login";

export const routes: any[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <DashboardMain />,
        test: "test",
      },

      // Category
      {
        path: "/category/create",
        element: <CategoryCreate />,
      },
      {
        path: "/category/search",
        element: <CategorySearch />,
      },
      {
        path: "/category/edit/:categoryId",
        element: <CategoryEdit />,
      },

      // Product
      {
        path: "/product/search",
        element: <ProductSearch />,
      },
      {
        path: "/product/create",
        element: <ProductCreate />,
      },
      {
        path: "/product/edit/:productId",
        element: <ProductEdit />,
      },

      // Orders
      {
        path: "/order/search",
        element: <OrderSearch />,
      },
      {
        path: "/order/edit/:orderId",
        element: <OrderEdit />,
      },

      // Cart
      {
        path: "/cart/search",
        element: <CartSearch />,
      },

      // Cashier
      {
        path: "/cashier",
        element: <Cashier />,
      },
    ],
  },
  {
    path: "*",
    element: <Login />,
  },
];
