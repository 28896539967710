export const QUERY_KEYS = {
  // Categories
  GET_CATEGORY_BY_ID: "GET_CATEGORY_BY_ID",
  GET_CATEGORY_TREE: "GET_CATEGORY_TREE",
  GET_CATEGORY_TREE_NODE: "GET_CATEGORY_TREE_NODE",

  // Products
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_PRODUCT_BY_ID: "GET_PRODUCT_BY_ID",
  GET_PRODUCT_IMAGES: "GET_PRODUCT_IMAGES",

  // Orders
  GET_ORDERS: "GET_ORDERS",
  GET_ORDER_BY_ID: "GET_ORDER_BY_ID",

  // Cashier
  GET_CASHIER_MOVEMENTS: "GET_CASHIER_MOVEMENTS",
  GET_CASHIER_STATS: "GET_CASHIER_STATS",

  // User
  GET_CURRENT_USER: "GET_CURRENT_USER"
}
