import api from "../../../lib/api";
import {ENDPOINTS} from "../../../data/endpoints";
import {ProductCreateType} from "../types/ProductCreateType";
import {ProductType} from "../types/ProductType";
import {ProductUpdateMainData} from "../types/ProductUpdateMainData";
import {ProductUpdateDesc} from "../types/ProductUpdateDesc";
import {ProductUpdateDetails} from "../types/ProductUpdateDetails";
import {ProductUpdatePromoType} from "../types/PromoUpdatePromoType";
import {ProductImageType} from "../types/ProductImageType";

const createProduct = async (createProduct: ProductCreateType) => {
  return await api.post(ENDPOINTS.CREATE_PRODUCT, createProduct);
}

const updateProductMainData = async (productUpdateMainData: ProductUpdateMainData) => {
  console.log(productUpdateMainData.categories)
  console.log(Object.keys(productUpdateMainData.categories))
  return await api.patch(ENDPOINTS.PRODUCT_UPDATE_MAIN_DATA, productUpdateMainData);
}

const updateProductDesc = async (data: ProductUpdateDesc) => {
  return await api.patch(ENDPOINTS.PRODUCT_UPDATE_DESC, data);
}

const updateProductDetails = async (data: ProductUpdateDetails) => {
  return await api.patch(ENDPOINTS.PRODUCT_UPDATE_DETAILS, data);
}

const updateProductPromo = async (data: ProductUpdatePromoType) => {
  return await api.patch(ENDPOINTS.PRODUCT_UPDATE_PROMO, data);
}

const getAllProducts = async () => {
  return await api.get<ProductType[]>(ENDPOINTS.GET_ALL_PRODUCTS);
}

const getProductById = async (productId: string) => {
  return await api.get<ProductType>(ENDPOINTS.GET_PRODUCT_BY_ID + productId);
}

const getProductImages = async (productId: string) => {
  return await api.get<ProductImageType[]>(ENDPOINTS.GET_PRODUCT_IMAGES + productId);
}

const updateProductMainImage = async (productId: string, imageId: string) => {
  return await api.patch(ENDPOINTS.PRODUCT_UPDATE_MAIN_IMAGE + productId + "/" + imageId);
}

const deleteProductImage = async (imageId: string) => {
  return await api.delete(ENDPOINTS.PRODUCT_DELETE_IMAGE + imageId)
}

const ProductService = {
  createProduct,
  updateProductMainData,
  updateProductDesc,
  getAllProducts,
  getProductById,
  updateProductDetails,
  updateProductPromo,
  getProductImages,
  updateProductMainImage,
  deleteProductImage
}

export default ProductService