import React, {useEffect} from "react";
import InputText from "../../../components/InputText";
import InputCurrency from "../../../components/InputCurrency";
import {Checkbox} from "primereact/checkbox";
import TreeSelect from "../../../components/TreeSelect";
import {useCategoryTreeNode} from "../../../features/category/hooks/useGetCategoryTreeNode";
import {generateSlug} from "../../../util/generateSlug";

interface Props {
    formik: any;
}

export const ProductMainData: React.FC<Props> = ({formik}) => {

    const {data} = useCategoryTreeNode();

    const isFormFieldInvalid = (name: keyof typeof formik.touched) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getInvalidMessage = (name: keyof typeof formik.touched) => {
        return isFormFieldInvalid(name) ? formik.errors[name] : undefined;
    };

    useEffect(() => {
        if (!formik.touched.slug) {
            const slug = generateSlug(formik.values.name);
            formik.setFieldValue("slug", slug);
        }
    }, [formik.values.name]);

    return (
        <div className="w-full xl:w-1/3">
            <InputText
                label="Име на продукта"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                invalid={isFormFieldInvalid("name")}
                invalidMessage={getInvalidMessage("name")}
            />

            <InputText
                label="Слъг"
                name="slug"
                value={formik.values.slug}
                onChange={formik.handleChange}
                wrapperClassName="mt-2"
                invalid={isFormFieldInvalid("slug")}
                invalidMessage={getInvalidMessage("slug")}
            />

            <div className="mt-2">
                <label>Категории</label>
                <TreeSelect
                    filter
                    onChange={(e: any) => {
                        console.log(e.target.value)
                        formik.setFieldValue("categoriesNode", e.target.value)
                    }}
                    options={data}
                    value={formik.values.categoriesNode}
                    metaKeySelection={false}
                    className="md:w-20rem w-full"
                    selectionMode="multiple"
                    placeholder="Избери категории"
                />
            </div>

            <InputCurrency
                label="Цена с ДДС"
                name="singlePrice"
                value={formik.values.singlePrice}
                onChange={e => formik.setFieldValue("singlePrice", e.value)}
                wrapperClassName="mt-2"
                invalid={isFormFieldInvalid("singlePrice")}
                invalidMessage={getInvalidMessage("singlePrice")}
            />

            <div className="flex items-center mt-2">
                <Checkbox
                    id="hasFreeInstallation"
                    name="hasFreeInstallation"
                    onChange={formik.handleChange}
                    checked={formik.values.hasFreeInstallation}
                    className="mr-2"
                />
                <label htmlFor="hasFreeInstallation">Безплатен монтаж</label>
            </div>

            <div className="flex items-center mt-2">
                <Checkbox
                    id="hasWifi"
                    name="hasWifi"
                    onChange={formik.handleChange}
                    checked={formik.values.hasWifi}
                    className="mr-2"
                />
                <label htmlFor="hasWifi">Wifi</label>
            </div>

            <div className="flex items-center mt-2">
                <Checkbox
                    id="isService"
                    name="isService"
                    onChange={formik.handleChange}
                    checked={formik.values.isService}
                    className="mr-2"
                />
                <label htmlFor="isService">Услуга</label>
            </div>

            <div className="flex items-center mt-2">
                <Checkbox
                    id="isActive"
                    name="isActive"
                    onChange={formik.handleChange}
                    checked={formik.values.isActive}
                    className="mr-2"
                />
                <label htmlFor="isActive">Активен</label>
            </div>

            <div className="flex items-center mt-2">
                <Checkbox
                    id="isOnlineStoreProduct"
                    name="isOnlineStoreProduct"
                    onChange={formik.handleChange}
                    checked={formik.values.isOnlineStoreProduct}
                    className="mr-2"
                />
                <label htmlFor="isOnlineStoreProduct">Да се показва в онлайн магазина</label>
            </div>

            <div className="flex items-center mt-2">
                <Checkbox
                    id="hasAdditionalWarranty"
                    name="hasAdditionalWarranty"
                    onChange={formik.handleChange}
                    checked={formik.values.hasAdditionalWarranty}
                    className="mr-2"
                />
                <label htmlFor="hasAdditionalWarranty">Опция за доп. гаранция</label>
            </div>

            {formik.values.hasAdditionalWarranty &&
                <InputCurrency
                    label="Цена на доп. гаранция с ДДС"
                    name="additionalWarrantyPrice"
                    value={formik.values.additionalWarrantyPrice}
                    onChange={e => formik.setFieldValue("additionalWarrantyPrice", e.value)}
                    wrapperClassName="mt-2"
                    invalid={isFormFieldInvalid("additionalWarrantyPrice")}
                    invalidMessage={getInvalidMessage("additionalWarrantyPrice")}
                />
            }
        </div>
    )
}

export default ProductMainData;