import api from "../../../lib/api";
import {CategoryTreeType} from "../types/CategoryTreeType";
import {ENDPOINTS} from "../../../data/endpoints";
import {CategoryCreateType} from "../types/CategoryCreateType";
import {TreeNode} from "primereact/treenode";
import {CategoryType} from "../types/CategoryType";
import {CategoryUpdateType} from "../types/CategoryUpdateType";

const getCategoryTree = async () => {
  return await api.get<CategoryTreeType[]>(ENDPOINTS.GET_CATEGORY_TREE);
};

const getCategoryById = async (categoryId: string) => {
  return await api.get<CategoryType>(ENDPOINTS.GET_CATEGORY_BY_ID + categoryId);
}

const getCategoryTreeNode = async () => {
  return await api.get<TreeNode[]>(ENDPOINTS.CATEGORY_TREE_NODE);
};

const createCategory = async (categoryCreateType: CategoryCreateType) => {
  return await api.post(ENDPOINTS.CATEGORY_CREATE, categoryCreateType);
}

const updateCategory = async (categoryUpdateType: CategoryUpdateType) => {
  return await api.post(ENDPOINTS.CATEGORY_UPDATE, categoryUpdateType);
}

const CategoryService = {
  getCategoryTree,
  getCategoryTreeNode,
  getCategoryById,
  createCategory,
  updateCategory
}

export default CategoryService;
